import { salons } from "./salons-hours";
import Link from "next/link";

interface OurSalonsHoursProps {
  showTitle?: boolean;
}

export function OurSalonsHours({ showTitle = true }: OurSalonsHoursProps) {
  return (
    <div className="max-w-8xl w-full px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96">
      {showTitle && (
        <h2 className="mb-12 font-cormorant text-4xl ph:text-5xl sm:text-6xl lg:mb-16 lg:text-7xl">
          NOS SALONS
        </h2>
      )}

      <div className="grid grid-cols-1 gap-16 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-0">
        {salons.map((salon) => (
          <div key={salon.id} className="flex flex-col">
            {/* Salon name */}
            <h2 className="mb-6 text-2xl lg:text-3xl">{salon.name}</h2>

            {/* Address */}
            <div className="text-semibold mb-6 space-y-1 font-bold lg:text-lg">
              <p>{salon.address.street}</p>
              <p>
                {salon.address.postalCode} {salon.address.city}
              </p>
            </div>

            {/* Hours - maintaining exact spacing and format */}
            <div className="mb-6 space-y-1 lg:text-lg">
              <div className="flex gap-2">
                <span>Lundi :</span>
                <span>{salon.hours.monday}</span>
              </div>
              <div className="flex gap-2">
                <span>Mardi :</span>
                <span>{salon.hours.tuesday}</span>
              </div>
              <div className="flex gap-2">
                <span>Mercredi :</span>
                <span>{salon.hours.wednesday}</span>
              </div>
              <div className="flex gap-2">
                <span>Jeudi :</span>
                <span>{salon.hours.thursday}</span>
              </div>
              <div className="flex gap-2">
                <span>Vendredi :</span>
                <span>{salon.hours.friday}</span>
              </div>
              <div className="flex gap-2">
                <span>Samedi :</span>
                <span>{salon.hours.saturday}</span>
              </div>
              <div className="flex gap-2">
                <span>Dimanche :</span>
                <span>{salon.hours.sunday}</span>
              </div>
            </div>

            {/* Phone number */}
            <p className="mb-4 font-normal lg:text-lg">{salon.phone}</p>

            {/* Appointment button - with animated underline */}
            <div>
              <Link
                href={salon.planityLink}
                className="group relative inline-block pb-[2px] lg:text-lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>PRENDRE RENDEZ-VOUS</span>
                <div className="absolute bottom-0 left-0 h-[1px] w-full bg-black">
                  <div className="h-full w-0 bg-gold transition-all duration-300 ease-out group-hover:w-full"></div>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
