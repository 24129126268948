import Image from "next/image";
import Link from "next/link";
import { ourSalonsImagesData } from "./salons-images";

export function OurSalonsImages() {
  return (
    <section
      className="max-w-8xl flex w-full flex-col gap-12 px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      id="nos-salons"
    >
      <h2 className="font-cormorant text-4xl ph:text-5xl sm:text-6xl lg:text-7xl">
        NOS SALONS DE COIFFURE
      </h2>
      <div className="grid grid-cols-1 gap-8 ph:grid-cols-2 lg:grid-cols-3">
        {ourSalonsImagesData.map((salon) => (
          <Link
            key={salon.id}
            href={`/le-salon/${salon.id}`}
            className="group flex flex-col items-center gap-4"
            title={`Découvrir ${salon.name}`}
          >
            <div className="relative aspect-square w-full overflow-hidden">
              <Image
                src={salon.imagePath}
                alt={`${salon.name} - ${salon.address}`}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
                className="object-cover transition-transform duration-300 group-hover:grayscale"
              />
            </div>
            <div className="text-center">
              <h3 className="text-xl font-medium">{salon.name}</h3>
              <p className="mt-2 text-sm text-gray-600">{salon.address}</p>
              <p className="mt-1 text-sm text-gray-600">
                {salon.postalCode} {salon.city}
              </p>
            </div>
          </Link>
        ))}
      </div>
      <div className="mx-auto max-w-2xl text-center text-sm text-gray-600">
        <p>
          Découvrez l&apos;excellence de nos salons de coiffure à Villejuif et
          Ville d&apos;Avray. Nos équipes d&apos;experts coiffeurs vous
          accueillent pour des prestations haut de gamme : coloration, balayage,
          coupe et soins.
        </p>
      </div>
    </section>
  );
}
